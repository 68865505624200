import { Navigate } from 'react-router-dom';
import { DashboardFormWrapper } from '../dashboard';
import { ContainersPage } from './ContainersComponents';
import { ContainersOutHeader, ContainersOutForm } from './ContainersOutComponents';
import { useStore } from '../../context';
import { showToRoles } from '../../utils';

export const ContainersOut = () => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  if (showToRoles('client', user)) return <Navigate to={'/dashboard/containers'} replace />;

  return (
    <ContainersPage>
      <ContainersOutHeader />
      <DashboardFormWrapper>
        <ContainersOutForm />
      </DashboardFormWrapper>
    </ContainersPage>
  );
};
